<template>
  <div>
    <manager />
  </div>
</template>

<script>
import Manager from '@/views/Dashboard/Manager.vue'

export default {
  components: {
    Manager,
  },
  methods: {},
}
</script>
